import React from "react";
import theme from "theme";
import { Theme, Link, Image, LinkBox, Strong, Text, Box, Section, Span, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Timeless Nations - Crypto Gaming
			</title>
			<meta name={"description"} content={" \"Timeless Nations\" is an exploration and civilization-building game where players, as members of an ancient and mysterious race, leap through time and dimensions to help various creatures evolve, build societies, and eventually unite with the player's ancient race."} />
			<meta property={"og:title"} content={"Timeless Nations - Crypto Gaming"} />
			<meta property={"og:description"} content={" \"Timeless Nations\" is an exploration and civilization-building game where players, as members of an ancient and mysterious race, leap through time and dimensions to help various creatures evolve, build societies, and eventually unite with the player's ancient race."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17:58:27.379Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section quarkly-title="Header" padding="44px 0 44px 0">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<LinkBox align-items="flex-start">
				<Image
					src="https://uploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23:10:50.216Z"
					width="100px"
					height="auto"
					lg-max-width="150px"
					srcSet="https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/LogoSketch.png?v=2023-09-27T23%3A10%3A50.216Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</LinkBox>
			<Components.QuarklycommunityKitMobileSidePanel>
				<Override slot="Content" background="rgba(255, 255, 255, 0)" padding="0px 0px 0px 0px" md-background="--color-dark" />
				<Override slot="Button Icon" md-color="--green" />
				<Override slot="Button Text" md-display="none" />
				<Override slot="Button Icon :closed" category="fi" icon={FiMenu} />
				<Override slot="Cross" md-color="--light" />
				<Box
					md-justify-content="center"
					md-padding="30px 0px 30px 0px"
					display="flex"
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
				>
					<Text
						margin="0px 25% 0px 0px"
						color="#ff0000"
						font="32px sans-serif"
						display="block"
						position="fixed"
						bottom="3186.2483492279052px"
						height="47.08335077209473px"
						left="441.167px"
						right="14.770603088378891px"
						top="12px"
						width="599.5523969116211px"
						text-align="center"
					>
						<Strong>
							UNDER DEVELOPMENT
						</Strong>
					</Text>
					<Link
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="#"
						padding="6px 0px 6px 0px"
						margin="0px 16px 0px 16px"
						text-decoration-line="initial"
						hover-border-color="--color-green"
						border-style="solid"
						color="--light"
						border-width="1px 0px 0px 0px"
						lg-margin="0px 16px 0px 0px"
						md-margin="0px 0px 7px 0px"
						font="--base"
						hover-color="--green"
						border-color="rgba(255, 255, 255, 0)"
					>
						Link1
					</Link>
					<Link
						padding="6px 0px 6px 0px"
						margin="0px 0px 0px 16px"
						hover-color="--green"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-green"
						border-width="1px 0px 0px 0px"
						href="#"
						color="--light"
						border-color="rgba(255, 255, 255, 0)"
						border-style="solid"
						lg-margin="0px 0px 0px 0px"
						text-decoration-line="initial"
						font="--base"
					>
						Link2
					</Link>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					<Span font-weight="normal">
						Timeless Nations
					</Span>
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
					position="static"
				>
					<Span
						font-weight="normal"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Leap through timeless realms, shape civilizations, and unite nations in a universe{" "}
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="#a5e50d"
						>
							beyond imagination
						</Span>
						.
						<br />
					</Span>
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17:58:27.379Z"
					max-height="600px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/TimelessNations.png?v=2023-09-26T17%3A58%3A27.379Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline2"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Shaping Civilizations: A Journey Beyond Time
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					"Timeless Nations" is an immersive game where players, as members of an ancient race, journey across diverse worlds and eras. Using avatars, players interact with native creatures, guiding their evolution, fostering societies, and building economies. Our mission is to offer a cooperative gaming experience that emphasizes collaboration over conflict, inviting players to shape and unite civilizations across the cosmos.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22:48:27.724Z"
					max-height="805.188px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/SilvaniOfDriftel-weblong.png?v=2023-09-27T22%3A48%3A27.724Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-light"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline2"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Stages of Developing Immersive NPC Interactions
					</Span>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Step into a game where characters learn directly from you! Our innovative approach crafts NPCs that adapt and evolve based on your voice commands and gestures. It's more than just gameplay; it's a chance to shape a virtual world with your influence. Dive in and experience the next level of interactive gaming!
						<br />
						<br />
						<Strong>
							1.   Voice and Visual Input Recognition:
						</Strong>
						<br />
					</Span>
					Utilize Implement basic voice commands for NPC actions.
Incorporate gesture recognition for visual cues.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						2.   Basic NPC Responses:
						<br />
					</Strong>
					Establish simple NPC actions based on voice or gestures.
Provide visual and audio feedback for players.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23:18:34.739Z"
					max-height="805.188px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/366944_Depict%20a%20female%20humanoid%20alien%20named%20Silvani.%20She%20_xl-beta-v2-2-2.png?v=2023-09-27T23%3A18%3A34.739Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-light"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						3. Testing and Refinement
					</Strong>
					<br />
					Test with players for usability and feedback.
Make necessary refinements based on insights.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						4. NPC Learning through Reinforcement Learning (RL):
					</Strong>
					<br />
					Introduce NPCs' ability to learn from player interactions using RL.
Ensure NPCs adapt and evolve based on player feedback.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						5.   Expand Complexity Gradually:
						<br />
					</Strong>
					Enhance voice and visual command intricacy as feedback accumulates.
Delve deeper into advanced AI techniques for improved NPC dynamics.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 60px 0" md-padding="20px 0 30px 0">
			<Override slot="SectionContent" position="relative" min-height="500px" lg-min-height="292px" />
			<Box
				width="100%"
				height="100%"
				position="absolute"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6513191272a6d30018296666/images/TimelessNationsbg.png?v=2023-09-26T20:22:48.656Z) 50% 50% /cover no-repeat scroll padding-box"
				z-index="-1"
				filter="grayscale(100%)"
			/>
			<Components.QuarklycommunityKitPopup
				height="100%"
				width="100%"
				align-items="center"
				display="flex"
				justify-content="center"
			>
				<Override
					slot="Button Open"
					hover-opacity=".8"
					transition="opacity 0.3s ease-in-out 0s"
					padding="0px 0px 0px 0px"
					height="70px"
					color="rgba(255, 255, 255, 0)"
					background="rgba(0, 119, 204, 0) url(https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/ant-design_play-circle-filled.png?v=2021-10-13T16:00:02.176Z) center center/auto no-repeat"
					width="70px"
				>
					Open Popup
				</Override>
				<Override slot="Wrapper" width="80%" />
				<Override slot="Button Close" size="40px" />
				<Box>
					<Components.QuarklycommunityKitYouTube autoplay={false} url="https://www.youtube.com/watch?v=LzpXjjubShQ" showOverlay={false} />
				</Box>
			</Components.QuarklycommunityKitPopup>
		</Section>
		<Section padding="80px 0 160px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					src="https://uploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19:55:09.114Z"
					width="120px"
					height="auto"
					lg-max-width="150px"
					srcSet="https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6513191272a6d30018296666/images/Kr%C3%B3not%C3%A1rr_logo.png?v=2023-11-12T19%3A55%3A09.114Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box margin="28px 0px 0px 0px" display="flex" md-margin="28px 0px 0px 0px">
					<Link
						color="--grey"
						padding="6px 0px 6px 0px"
						hover-color="--green"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="#"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-green"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Contact
					</Link>
				</Box>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="40%"
				md-width="100%"
				md-margin="24px 0px 0px 0px"
				empty-min-width="64px"
			>
				<Text
					md-text-align="left"
					margin="0px 0px 26px 0px"
					color="--light"
					font="--headline4"
					text-align="right"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				>
					Let’s stay in touch
				</Text>
				<Components.QuarklycommunityKitNetlifyForm
					display="flex"
					align-items="center"
					justify-content="flex-end"
					md-justify-content="flex-start"
					formName="Collect Email"
				>
					<Override slot="Form" display="flex" align-items="center" justify-content="center" />
					<Input
						name="Email"
						placeholder="Give us your email..."
						type="email"
						required
						border-width="1px"
						color="--grey"
						border-radius="0px"
						background="rgba(255, 255, 255, 0)"
						border-color="--color-green"
						padding="12px 16px 10px 16px"
					/>
					<Button
						border-radius="0px"
						hover-opacity=".8"
						transition="opacity 0.3s --transitionTimingFunction-easeInOut 0s"
						position="relative"
						top="-1px"
						background="--color-green url(https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/arrow_right_alt.png?v=2021-10-14T12:16:06.886Z) center center/auto no-repeat"
						width="56px"
						height="51px"
						color="rgba(255, 255, 255, 0)"
					>
						.
					</Button>
				</Components.QuarklycommunityKitNetlifyForm>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});